import React from "react"
import { StripWrapper } from "./styles"
export function Strip() {
	return (
		<StripWrapper
			href="https://imjo.in/rQDwdg"
			target="_blank"
			rel="noreferrer"
		>
			<h2>YES! I want to get started on figuring my money scene out ⟶</h2>
		</StripWrapper>
	)
}
